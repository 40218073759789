import Vue from 'vue'
import App from './App.vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueRouter from 'vue-router'
import router from './router'
import ElementUI from 'element-ui';
import Distpicker from 'v-distpicker'
import Screenfull from 'screenfull'
import VueAMap from "vue-amap";
import * as echarts from 'echarts';
import 'element-ui/lib/theme-chalk/index.css';
import './assets/css/style.css'

Vue.config.productionTip = false;
Vue.use(VueRouter);
Vue.use(VueAMap);
Vue.use(ElementUI);
Vue.use(Screenfull);
Vue.use(VueAxios, axios);
Vue.prototype.$echarts = echarts;
Vue.prototype.$full = Screenfull;
Vue.component('v-distpicker', Distpicker);
Vue.prototype.$baseUrl = "https://elders-api.iradar.cn";
Vue.prototype.handleCommand = function (command) {
  if (command == 'logout') {
    localStorage.setItem("account", '');
    localStorage.setItem("password", '');
    router.replace('/');
  }
};

// const amapKeys = Object.keys(localStorage).filter(key => key.match(/^_AMap_/))

// amapKeys.forEach(key => {
//   // console.log(key)
//   localStorage.removeItem(key)
// })

VueAMap.initAMapApiLoader({
  // key: '168724d303b441e7298054c388cb3f2e',
  key: 'a4390092ecfe6a9b5865dc05616534e6',
  plugin: ['AMap.Autocomplete', 'AMap.PlaceSearch', 'AMap.Scale', 'AMap.OverView', 'AMap.ToolBar', 'AMap.MapType', 'AMap.PolyEditor', 'AMap.CircleEditor','AMap.Geocoder','MarkerClusterer', 'AMap.Geolocation'],
  v: '1.4.4',
});
new Vue({
  el: '#app',
  router,
  render: h => h(App),
}).$mount('#app')
