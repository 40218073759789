<template>
  <div class="container">
    <img
      src="../assets/pic_chahua.png"
      style="position: absolute; left: 109px; bottom: 230px"
    />
    <span
      style="
        font-size: 60px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        position: absolute;
        left: 180px;
        bottom: 700px;
        white-space:nowrap;
      "
      >关爱老人</span
    >
    <span
      style="
        font-size: 60px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        position: absolute;
        left: 180px;
        bottom: 609px;
        white-space:nowrap;
      "
      >全方位、全天候守护老人</span
    >
    <span
      style="
        font-size: 24px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        position: absolute;
        left: 64px;
        bottom: 36px;
        white-space:nowrap;
      "
      >瓦戈智慧老年卡</span
    >
    <div class="login" v-show="!register && !forgot">
      <div class="typeName">登录</div>
      <div class="account">
        <el-input
          class="input"
          v-model="account"
          placeholder="请输入您的帐号"
          @input="print"
        ></el-input>
      </div>
      <div class="password">
        <el-input
          class="input"
          v-model="password"
          placeholder="请输入您的密码"
          type="password"
          @input="print"
        ></el-input>
      </div>
      <div class="options">
        <el-checkbox v-model="rememberMe">下次自动登录</el-checkbox>
      </div>
      <el-button class="confirm" type="primary" @click="authAndGo"
        >登录</el-button
      >
      <div class="options">
        <div class="goRegister" @click="register = true">
          没有账号，去注册？
        </div>
        <div
          class="switch"
          @click="
            forgot = true;
            register = false;
            password = '';
          "
        >
          忘记密码
        </div>
      </div>
      <!-- <el-dialog title="提示" :visible.sync="showAlert" width="30%">
        <span>演示版本暂不开放此功能</span>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="showAlert = false">确 定</el-button>
        </span>
      </el-dialog> -->
    </div>
    <div class="login" v-show="forgot">
      <div class="typeName">忘记密码</div>
      <div class="account">
        <el-input
          class="input"
          v-model="phoneNumber"
          placeholder="请输入手机号 (登录账号)"
          @input="print"
        ></el-input>
      </div>
      <div class="account">
        <el-input
          class="input"
          v-model="captcha"
          placeholder="请输入验证码"
          @input="print"
        ></el-input>
        <el-button
          class="confirm"
          type="primary"
          @click="getCode"
          :disabled="buttonDisable"
          >{{ codeButton }}</el-button
        >
      </div>
      <div class="password">
        <el-input
          class="input"
          v-model="password"
          placeholder="请输入新的密码（6-15位数字和字母）"
          type="password"
          @input="print"
        ></el-input>
      </div>
      <el-button class="confirm" type="primary" @click="changePassword"
        >确认修改</el-button
      >
      <div class="options">
        <div
          class="switch"
          @click="
            register = false;
            forgot = false;
            captcha = '';
            password = '';
          "
        >
          返回登录
        </div>
      </div>
    </div>
    <div class="register" v-show="register">
      <div class="typeName" style="margin-top: 50px; margin-bottom: 50px">
        注册
      </div>
      <div class="account">
        <el-input
          class="input"
          v-model="schoolName"
          placeholder="请输入院区名称"
          @input="print"
        ></el-input>
      </div>
      <!-- <div class="account">
        <el-input
          class="input"
          v-model="schoolId"
          placeholder="请输入院区编号"
          @input="print"
        ></el-input>
      </div> -->
      <div class="account">
        <v-distpicker class="input" @selected="selected"></v-distpicker>
      </div>
      <div class="account">
        <el-input
          class="input"
          v-model="managerName"
          placeholder="请输入负责人姓名"
          @input="print"
        ></el-input>
      </div>
      <div class="account">
        <el-input
          class="input"
          v-model="phoneNumber"
          placeholder="请输入手机号 (登录账号)"
          oninput="value=value.replace(/[^\d]/g,'')"
          @input="print"
        ></el-input>
      </div>
      <div class="account">
        <el-input
          class="input"
          v-model="captcha"
          placeholder="请输入验证码"
          oninput="value=value.replace(/[^\d]/g,'')"
          @input="print"
        ></el-input>
        <el-button
          class="confirm"
          type="primary"
          @click="getCode"
          :disabled="buttonDisable"
          >{{ codeButton }}</el-button
        >
      </div>
      <div class="password">
        <el-input
          class="input"
          v-model="userPassword"
          placeholder="请输入6-15位数字和字母"
          type="password"
          @input="print"
        ></el-input>
      </div>
      <div class="options">
        <el-checkbox v-model="userRead"
          >我已阅读<a href="javascript:void(0)">《智慧老年卡隐私协议》</a>和<a
            href="javascript:void(0)"
            >《用户条款》</a
          ></el-checkbox
        >
      </div>
      <el-button class="confirm" type="primary" @click="goRegister"
        >立即注册</el-button
      >
      <div class="options">
        <div class="switch" @click="register = false; captcha = ''">返回登录</div>
      </div>
    </div>
    <el-dialog title="提示" :visible.sync="showLoginAlert" width="30%">
      <span>账号或密码错误</span>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="showLoginAlert = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <el-dialog title="提示" :visible.sync="showErrorAlert" width="30%">
      <span>{{ errorMessage }}</span>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="showErrorAlert = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <el-dialog title="提示" :visible.sync="showSuccess" width="30%">
      <span>注册成功</span>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="
            showSuccess = false;
            register = false;
          "
          >登录</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import encrypt from '@/Api/encrypt'
export default {
  name: "Login",
  mounted() {
    if (
      localStorage.getItem("account").length > 0 &&
      localStorage.getItem("password").length > 0
    ) {
      this.account = localStorage.getItem("account");
      this.password = localStorage.getItem("password");
      this.authAndGo();
    }
  },
  data() {
    return {
      account: "",
      password: "",
      rememberMe: false,
      register: false,
      showAlert: false,
      forgot: false,
      showLoginAlert: false,
      showErrorAlert: false,
      showSuccess: false,
      userRead: false,
      buttonDisable: false,
      codeButton: "获取验证码",
      schoolName: "",
      managerName: "",
      phoneNumber: "",
      captcha: "",
      userPassword: "",
      errorMessage: "",
      location: "",
      schoolId: "",
      regionCode: "",
      area: "",
      city: "",
      province: "",
      schoolAddress: "",
      options: [
        {
          value: "选项1",
          label: "黄金糕",
        },
        {
          value: "选项2",
          label: "双皮奶",
        },
        {
          value: "选项3",
          label: "蚵仔煎",
        },
        {
          value: "选项4",
          label: "龙须面",
        },
        {
          value: "选项5",
          label: "北京烤鸭",
        },
      ],
    };
  },
  methods: {
    changePassword() {
      this.$http
        .get(
          `${this.$baseUrl}/sms/check?phone=${this.phoneNumber}&code=${this.captcha}`
        )
        .then((response) => {
          if (
            response.data.code != 200 ||
            response.data.result != "验证码校验成功"
          ) {
            this.errorMessage = "请输入正确的验证码";
            this.showErrorAlert = true;
            return;
          }
          this.$http
            .get(
              `${this.$baseUrl}/school/info/findSchools?page=1&size=10&account=${this.phoneNumber}`
            )
            .then((response) => {
              if (response.data.result.records.length < 1) {
                this.errorMessage = "该帐号不存在";
                this.showErrorAlert = true;
                return;
              }
              let temp = response.data.result.records[0];
              temp.password = encrypt.Encrypt(this.password, 'vargovargovargo1');
              if (temp.password.length < 0 || new RegExp("^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,15}$").test(this.password) == false) {
                this.errorMessage = "密码不合法";
                this.showErrorAlert = true;
                return;
              }
              this.$http
                .put(`${this.$baseUrl}/school/info/update`, temp)
                .then((response) => {
                  if (response.data.code != 200) {
                    this.errorMessage = "修改失败";
                    this.showErrorAlert = true;
                    return;
                  }
                  this.errorMessage = "修改成功";
                  this.password = "";
                  this.showErrorAlert = true;
                  this.forgot = false;
                  this.register = false;
                });
            });
        });
    },
    selected(data) {
      console.log(data);
      this.regionCode = data.area.code;
      this.city = data.city.code;
      this.area = data.area.code;
      this.province = data.province.code;
      console.log(this.city, this.area, this.province);
      this.schoolAddress = `${data.province.value},${data.city.value},${data.area.value}`;
    },
    print() {
      console.log(this.captcha);
    },
    goRegister() {
      // let reg = "^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,20}$";
      if (this.userRead == false) {
        this.errorMessage = '请先阅读许可协议';
        this.showErrorAlert = true;
        return
      }
      this.schoolId = this.phoneNumber+'School'+(+new Date());
      if (this.phoneNumber == '' || this.userPassword == '' || this.area == '' || this.city == '' || this.province == '' || this.schoolName == '' || this.schoolAddress == '' || this.managerName == '' || this.schoolId == ''){
        this.errorMessage = '请填写完整信息';
        this.showErrorAlert = true;
        return
      }
      if (new RegExp("^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,15}$").test(this.userPassword) == false){
        this.errorMessage = '密码不合法';
        this.showErrorAlert = true;
        return
      }
      this.$http
        .get(
          `${this.$baseUrl}/sms/check?phone=${this.phoneNumber}&code=${this.captcha}`
        )
        .then((response) => {
          if (
            response.data.code != 200 ||
            response.data.result != "验证码校验成功"
          ) {
            this.errorMessage = response.data.message;
            this.showErrorAlert = true;
            return;
          }
          let userPassword = encrypt.Encrypt(this.userPassword, 'vargovargovargo1');
          this.$http
            .post(`${this.$baseUrl}/school/schoolRegister`, {
              account: this.phoneNumber,
              password: userPassword,
              area: this.area,
              city: this.city,
              province: this.province,
              schoolName: this.schoolName,
              schoolAddress: this.schoolAddress,
              schoolManager: this.managerName,
              schoolManagerPhone: this.phoneNumber,
              schoolId: this.schoolId,
            })
            .then((response) => {
              if (response.data.code != 200) {
                this.errorMessage = response.data.message;
                this.showErrorAlert = true;
                return;
              }
              this.showSuccess = true;
              this.phoneNumber = "";
              this.regionCode = this.city = this.area = this.province = "";
              this.userPassword = "";
              this.schoolName = "";
              this.schoolAddress = "";
              this.managerName = "";
              this.phoneNumber = "";
              this.captcha = "";

              this.$http
                .post(`${this.$baseUrl}/school/time/config/add`, {
                  schoolId: this.schoolId,
                  termType: 0,
                  timeConfigId:
                    this.schoolId +
                    "_" +
                    new Date(Date.now()).getFullYear() +
                    "_" +
                    "0",
                })
                .then((response) => {
                  if (response.data.code != 200) {
                    this.errorMessage = response.data.message;
                    this.showErrorAlert = true;
                    return;
                  }
                  this.$http
                    .post(`${this.$baseUrl}/school/time/config/add`, {
                      schoolId: this.schoolId,
                      termType: 1,
                      timeConfigId:
                        this.schoolId +
                        "_" +
                        new Date(Date.now()).getFullYear() +
                        "_" +
                        "1",
                    })
                    .then((response) => {
                      if (response.data.code != 200) {
                        this.errorMessage = response.data.message;
                        this.showErrorAlert = true;
                        return;
                      }
                      this.schoolId = "";
                    });
                });
            });
        })
        .catch(() => {
          this.errorMessage = "请输入正确的验证码";
          this.showErrorAlert = true;
          return;
        });
    },
    getCode() {
      console.log(this.phoneNumber);
      if (this.phoneNumber.length != 11) {
        this.errorMessage = "请输入正确的手机号";
        this.showErrorAlert = true;
        return;
      }
      this.$http.get(`${this.$baseUrl}/sms/send?phone=${this.phoneNumber}`);
      this.buttonDisable = true;
      let remain = 60;
      this.codeButton = `${remain}秒`;
      let interval = setInterval(() => {
        remain = remain - 1;
        this.codeButton = `${remain}秒`;
        if (remain == 0) {
          clearInterval(interval);
          this.buttonDisable = false;
          this.codeButton = "获取验证码";
        }
      }, 1000);
    },
    authAndGo() {
      let password = encrypt.Encrypt(this.password, 'vargovargovargo1');
      this.$http
        .post(`${this.$baseUrl}/school/login`, {
          account: this.account,
          password: password,
        })
        .then((response) => {
          if (response.data.code != 200) {
            this.showLoginAlert = true;
            return;
          }
          if (this.rememberMe) {
            localStorage.setItem("account", this.account);
            localStorage.setItem("password", this.password);
          }
          localStorage.setItem(
            "schoolData",
            JSON.stringify(response.data.result)
          );
          this.$http
            .post(`${this.$baseUrl}/card/info/DeviceOnlineDetail`, {
              schoolId: ''+JSON.parse(localStorage.getItem("schoolData")).schoolId,
            })
            .then((response) => {
              localStorage.setItem(
                "schoolStudents",
                response.data.result.school_students
              );
              this.$http
                .get(`${this.$baseUrl}/StudentsDevices/findStudentsDevicesByCondition?page=1&size=1&schoolId=${JSON.parse(localStorage.getItem("schoolData")).schoolId}`)
                .then((response) => {
                  localStorage.setItem(
                    "schoolDevices",
                    response.data.result.total
                  );
                  this.$router.push("/index");
                  console.log(localStorage.getItem("schoolDevices"));
                });
            });
          console.log(response);
        });
    },
    // authAndGo() {
    //   this.$http
    //     .post(`${this.$baseUrl}/school/login`, {
    //       account: this.account,
    //       password: this.password,
    //     })
    //     .then((response) => {
    //       if (response.data.code != 200) {
    //         this.showLoginAlert = true;
    //         return;
    //       }
    //       localStorage.setItem(
    //         "schoolData",
    //         JSON.stringify(response.data.result)
    //       );
    //       localStorage.setItem("schoolStudents", 4900);

    //       localStorage.setItem("schoolDevices", 4800);
    //       this.$router.push("/index");
    //       console.log(localStorage.getItem("schoolDevices"));
    //     });
    // },
  },
};
</script>

<style scoped>
a {
  text-decoration: none;
}

.typeName {
  margin-top: 60px;
  margin-bottom: 60px;
  font-size: 24px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
}

.el-button--primary {
  border-radius: 30px;
  width: 400px;
  height: 60px;
  font-size: 24px;
}

.input >>> .el-input__inner {
  height: 60px;
  border-radius: 30px;
}

.goRegister {
  cursor: pointer;
}

.options {
  font-size: 11px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  line-height: 16px;
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  width: 400px;
  height: 20px;
  margin-left: 68px;
}

.switch {
  position: absolute;
  right: 0;
  top: -2px;
  cursor: pointer;
}

.container {
  min-width: 1900px;
  min-height: 920px;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: linear-gradient(219deg, #79caf5 0%, #5d9cff 100%);
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
}
.dialog-footer {
  text-align: center;
}

.dialog-footer .el-button--primary {
  width: 30%;
  border-radius: 0px;
  height: 40px;
  border-radius: 5px;
  font-size: 12px;
}

.type {
  margin-right: 10px;
}

.account,
.password {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
  position: relative;
}

.account .el-button--primary {
  font-size: 12px;
  border-radius: 0 30px 30px 0;
}
.account .confirm {
  position: absolute;
  width: 100px;
  right: 68px;
  top: 0;
}
.confirm {
  width: 400px;
  margin-bottom: 24px;
}

.input {
  width: 400px;
}
.register .input {
  width: 400px;
  text-align: left;
}
.register .type {
  width: 50px;
  text-align: left;
}

.account >>> .distpicker-address-wrapper select {
  border-radius: 30px;
  height: 60px;
  width: 130px;
}

.register {
  padding: 20px;
  border: 1px solid white;
  width: 535px;
  min-height: 765px;
  background: #ffffff;
  box-shadow: 0px 2px 4px 0px #5d9cff;
  border-radius: 5px;
  position: absolute;
  top: calc(50% - 400px);
  right: 100px;
}
.login {
  padding: 20px;
  border: 1px solid white;
  width: 535px;
  height: 605px;
  background: #ffffff;
  box-shadow: 0px 2px 4px 0px #5d9cff;
  border-radius: 5px;
  position: absolute;
  bottom: calc(50% - 300px);
  right: 100px;
}
</style>